<template>
  <DashboardAuthLayout>
    <template #header>
      <img src="@/assets/images/careercolor.svg" alt="Logo" />
    </template>

    <div class="my-9 p-4 md:p-9 bg-white rounded w-full">
      <!-- SUCCESS DIALOG -->
      <Dialog v-show="displaySuccessDialog" class="backdrop-blur-sm">
        <div class="my-9 p-4 md:p-9 bg-white rounded w-full">
          <div class="flex flex-col justify-between items-center">
            <div>
              <SuccessSvg />
            </div>
            <div class="pt-4 flex flex-col justify-between items-center">
              <h1 class="font-cooper font-medium text-3xl text-[#090E11] text-center">Password reset successful!</h1>
              <!-- <span class="font-GTWalsheimPro font-normal text-sm text-[#090E11]/80 text-center">Your account has been created successfully.</span> -->
            </div>
            <button @click="$router.push('/login')" class="w-full bg-brand-primary text-white p-4 rounded-lg mt-8">Back to Login</button>
          </div>
        </div>
      </Dialog>

      <div class="flex flex-row justify-between items-center">
        <div class="flex flex-col">
          <h1 class="font-cooper font-medium text-3xl text-[#090E11]">Reset Password</h1>
          <span class="font-GTWalsheimPro font-normal text-sm text-[#090E11]/80">Create a new password</span>
        </div>
        <div>
          <circle-progress :percent="100" :size="64" :border-width="6" :border-bg-width="6" fill-color="#B34C99" empty-color="#B34C991F">
            <span class="flex flex-row items-center justify-between">
              <span class="font-bold text-3xl text-[#B34C99]">3</span>
              <span class="font-normal text-base text-black/40">/3</span>
            </span>
          </circle-progress>
        </div>
      </div>

      <div>
        <div class="pt-4 flex flex-col">
          <label for="password-icon" class="block mb-2 text-sm font-medium text-[#090E11]/70">Enter new Password</label>
          <div class="relative">
            <input
              :type="newPassword ? 'text' : 'password'"
              v-model="password"
              id="password-icon"
              class="bg-[#F2F2F2] border-0 text-[#090E11] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
            />

            <div class="flex absolute inset-y-0 right-0 items-center pr-3 cursor-pointer">
              <EyeSlash v-if="!newPassword" @click="newPassword = true" fill="black" />
              <TimesSvg v-else @click="newPassword = false" />
            </div>
          </div>
        </div>

        <div class="pt-4 flex flex-col">
          <label for="password-icon" class="block mb-2 text-sm font-medium text-[#090E11]/70">Confirm Password</label>
          <div class="relative">
            <input
              :type="confirmPassword ? 'text' : 'password'"
              v-model="confirm_password"
              id="password-icon"
              class="bg-[#F2F2F2] border-0 text-[#090E11] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
            />

            <div class="flex absolute inset-y-0 right-0 items-center pr-3 cursor-pointer">
              <EyeSlash v-if="!confirmPassword" @click="confirmPassword = true" fill="black" />
              <TimesSvg v-else @click="confirmPassword = false" />
            </div>
          </div>
        </div>

        <div class="pt-4">
          <button @click="resetPasswordAction" class="w-full bg-brand-primary text-white p-4 rounded-lg">Finish</button>
        </div>
      </div>
    </div>
  </DashboardAuthLayout>
</template>

<script setup>
import EyeSlash from '@/assets/icons/eye-slash.svg?inline';
import TimesSvg from '@/assets/icons/times.svg?inline';
import SuccessSvg from '@/assets/icons/success.svg?inline';
import DashboardAuthLayout from '@/layouts/DashboardAuthLayout.vue';
import CircleProgress from '@/components/CircleProgress.vue';
import Dialog from '@/components/Dialog.vue';
import { ref } from 'vue';
import { useStore } from 'vuex';
import { alert, errorMessage } from '@/utils/helper';

const displaySuccessDialog = ref(false);

function showSuccessDialog() {
  displaySuccessDialog.value = true;
}

const confirmPassword = ref(false);
const newPassword = ref(false);
const password = ref('');
const confirm_password = ref('');
const store = useStore();

const resetPasswordAction = async (value) => {
  try {
    const data = {
      email: await store.getters['global/getFormPart']('email'),
      token: await store.getters['auth/accessToken'],
      password: password.value,
      password_confirmation: confirm_password.value,
    };
    await store.dispatch('auth/forgetPasswordReset', data);
    store.commit('auth/setToken', null);
    showSuccessDialog();
  } catch (error) {
    if (error.response && error.response.status === 422) {
    }
    alert(errorMessage(error), 'error');
  }
};
</script>

<style></style>
